import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Dashboard } from "./components/dashboard";
import { UpdateParticipant } from "./components/updateParticipant";
import { Login } from "./components/login";
import { auth } from "./helpers/firebaseHelper";

export default function App() {
  const [user] = useAuthState(auth);
  if (!user) {
    return <Login />;
  } else return <Dashboard />;
}
