/** @jsxRuntime classic */
import React from "react";

import getMergedStyles from "../../helpers/getMergedStyles";
import { Button } from "../Button/Button";
import { List } from "../List/List";
import styles from "./ClickableList.styles";

interface ItemProp {
  content: string;
  url?: string;
  onClick?: () => void;
}

interface Props {
  header?: any;
  items: ItemProp[];
  variant?: string | string[];
  styles?: object;
}

export const ClickableList = ({
  header,
  items,
  variant = "",
  styles: parentStyles = {},
}: Props) => {
  const { root, title, list, listItem }: { [key: string]: any } =
    getMergedStyles(styles, parentStyles, variant);
  return (
    <div css={root}>
      {header && <h3 css={title}>{header}</h3>}
      <List
        items={items.map((item, index) => {
          const { content, url } = item;
          return {
            content: (
              <Button
                key={index}
                copy={content}
                {...(url ? { url } : {})}
                onClick={() => item.onClick && item.onClick()}
                styles={listItem}
              />
            ),
          };
        })}
        styles={list}
      />
    </div>
  );
};
