export default {
  primary: {
    darkGreen: '#2C4A43',
    dimGreen: '#3BBD9E',
    green: '#6CE4BF',
    blue: '#5DA7EB',
    darkBlue: '#4C8CC8',
    yellow: '#FFE768',
  },
  secondary: {
    lightBlue: '#ECF4F4',
    ultraLightBlue: '#EEF6FD',
    ultraLightGreen: '#F7FBFA',
    white: '#FFFFFF',
    greenGrey: '#F5F8F2',
  },
  accents: {
    purple: '#99A7EB',
    heartRed: '#EE8868',
  },
  border: {
    grey: '#D8DCD4',
  },
}
