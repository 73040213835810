import React from "react";
import { logout } from "../helpers/firebaseHelper";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const Sidebar = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  if (!user || !user.email) {
    return null;
  } else {
    return (
      <div className={"sidebar"}>
        <h1 className={"sidebarHeader"}>Lindus</h1>
        <div>
          <div>
            <button
              onClick={() => {
                navigate("/");
              }}
              className={`sidebarNavButton ${
                activeTab === "dashboard" && "activeNavButton"
              }`}
            >
              <span>Add New Participant</span>
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/update");
              }}
              className={`sidebarNavButton ${
                activeTab === "update" && "activeNavButton"
              }`}
            >
              <span>Update Participant</span>
            </button>
          </div>
        </div>
        <div className={"sidebarFooter"}>
          <div className={"sidebarFooterText"}>
            <h2 className={"cascade"}>
              <span>{user.displayName}</span>
            </h2>
          </div>
          <button
            className="cta login__btn login__google"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Sign out
          </button>
        </div>
      </div>
    );
  }
};
