import axios from "axios";
import { useState } from "react";
import { ClickableList } from "./ClickableList/ClickableList";

export const addressDetailsDefaultState: any = {
  addressLine1: {
    value: "",
    isValid: false,
  },
  addressLine2: {
    value: "",
    isValid: true,
  },
  townCity: {
    value: "",
    isValid: false,
  },
  county: {
    value: "",
    isValid: true,
  },
  country: {
    value: "United Kingdom",
    isValid: true,
  },
  postcode: {
    value: "",
    isValid: false,
  },
};

const addressListRequest = ({
  setIsLoading,
  setAddressLookupList,
  addressDetails,
  setAddressDetails,
  setExpandAddressFields,
  setAddressKey,
  failureCallback,
  setShowAddressDetails,
}: any) => {
  const getAddressesApiKey = process.env.REACT_APP_GET_ADDRESSES_API_KEY;
  const { value } = addressDetails;
  const { postcode } = value;
  setIsLoading(true);
  axios({
    method: "get",
    url: `https://api.getAddress.io/find/${postcode.value}?expand=true&api-key=${getAddressesApiKey}`,
  })
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      setIsLoading(false);
      setAddressLookupList(
        data.addresses.map(
          (addressOption: {
            line_1: any;
            line_2: any;
            town_or_city: any;
            county: any;
          }) => {
            return {
              addressLine1: addressOption.line_1,
              addressLine2: addressOption.line_2,
              city: addressOption.town_or_city,
              county: addressOption.county,
              onClick() {
                setShowAddressDetails(true);
                setAddressDetails({
                  value: {
                    ...value,
                    addressLine1: {
                      value: this.addressLine1,
                      isValid: true,
                    },
                    addressLine2: {
                      value: this.addressLine2,
                      isValid: true,
                    },
                    townCity: {
                      value: this.city,
                      isValid: true,
                    },
                    county: {
                      value: this.county,
                      isValid: true,
                    },
                  },
                  isValid: true,
                });
                setAddressLookupList([]);
              },
            };
          }
        )
      );
    })
    .catch(() => {
      setIsLoading(false);
      failureCallback();
    });
};

export const AddressLookup = ({
  setExpandAddressFields,
  addressDetails,
  setAddressDetails,
  addressLookupList,
  setAddressLookupList,
  addressKey,
  setAddressKey,
  failureCallback,
  styles: parentStyles = {},
  variant = "",
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showAddressDetails, setShowAddressDetails] = useState(false);

  return (
    <div>
      <input
        value={addressDetails.value?.details?.postcode}
        onChange={(e) => {
          setAddressDetails({
            value: {
              ...addressDetails.value,
              postcode: {
                value: e.target.value,
                isValid: true,
              },
            },
          });
        }}
        style={{ marginRight: 5 }}
        type={"text"}
      />
      <button
        onClick={() => {
          addressListRequest({
            setIsLoading,
            setAddressLookupList,
            addressDetails,
            setAddressDetails,
            setExpandAddressFields,
            setAddressKey,
            failureCallback,
            setShowAddressDetails,
          });
        }}
        className="cta"
      >
        Search
      </button>
      {!showAddressDetails && (
        <div>
          <button
            onClick={() => {
              setShowAddressDetails(true);
            }}
          >
            Enter address manually
          </button>
        </div>
      )}

      {!!addressLookupList.length && (
        <div style={{ maxHeight: 100, overflow: "scroll" }}>
          <ClickableList
            items={addressLookupList.map(
              (item: {
                onClick?: any;
                addressLine1?: any;
                addressLine2?: any;
                city?: any;
              }) => {
                const { addressLine1, addressLine2, city } = item;
                return {
                  content: `${addressLine1}${
                    addressLine2 ? ", " + addressLine2 : ""
                  }${city ? ", " + city : ""}`,
                  onClick() {
                    item.onClick && item.onClick();
                  },
                };
              }
            )}
          />
        </div>
      )}
      {showAddressDetails && (
        <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <label style={{ flex: 1 }}>Address Line 1</label>
            <input
              style={{ flex: 1 }}
              onChange={(e) => {
                setAddressDetails({
                  value: {
                    ...addressDetails.value,
                    addressLine1: {
                      value: e.target.value,
                      isValid: true,
                    },
                  },
                });
              }}
              value={addressDetails?.value?.addressLine1?.value}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <label style={{ flex: 1 }}>Address Line 2</label>
            <input
              style={{ flex: 1 }}
              onChange={(e) => {
                setAddressDetails({
                  value: {
                    ...addressDetails.value,
                    addressLine2: {
                      value: e.target.value,
                      isValid: true,
                    },
                  },
                });
              }}
              value={addressDetails?.value?.addressLine2?.value}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <label style={{ flex: 1 }}>Town/City</label>
            <input
              style={{ flex: 1 }}
              onChange={(e) => {
                setAddressDetails({
                  value: {
                    ...addressDetails.value,
                    townCity: {
                      value: e.target.value,
                      isValid: true,
                    },
                  },
                });
              }}
              value={addressDetails?.value?.townCity?.value}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <label style={{ flex: 1 }}>County</label>
            <input
              style={{ flex: 1 }}
              onChange={(e) => {
                setAddressDetails({
                  value: {
                    ...addressDetails.value,
                    county: {
                      value: e.target.value,
                      isValid: true,
                    },
                  },
                });
              }}
              value={addressDetails?.value?.county?.value}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <label style={{ flex: 1 }}>Postcode</label>
            <input
              style={{ flex: 1 }}
              onChange={(e) => {
                setAddressDetails({
                  value: {
                    ...addressDetails.value,
                    postcode: {
                      value: e.target.value,
                      isValid: true,
                    },
                  },
                });
              }}
              value={addressDetails?.value?.postcode?.value}
            />
          </div>
        </div>
      )}
    </div>
  );
};
