export default (variant: string | any[], item: string) => {
  if (Array.isArray(variant)) {
    return variant.includes(item);
  }

  if (typeof variant === "string") {
    return variant === item;
  }

  throw Error("variant passed is neither an array nor string");
};
