import { Fragment, useState, useEffect } from "react";
import "./globals.css";
import { Sidebar } from "./sidebar";
import axios from "axios";
import { nextMonday, format, addWeeks } from "date-fns";
import isEmailAddressValid from "../helpers/isEmailAddressValid";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const nextMondays = (
  currentPatient: any,
  setPatient: any,
  numberOfMondays?: number
) => {
  const numberOfOptions = numberOfMondays || 10;
  const options = [];
  for (let i = 1; i < numberOfOptions; i++) {
    const date = addWeeks(nextMonday(new Date()), i);
    if (date > new Date("2023-01-09") && options.length < 6) {
      options.push(date);
    }
  }
  const firstOption = format(options[0], "yyyy-MM-dd");
  if (firstOption !== currentPatient.selectedStartDate) {
    setPatient({
      ...currentPatient,
      selectedStartDate: format(options[0], "yyyy-MM-dd"),
    });
  }

  return options.map((o, index) => {
    const value = format(o, "yyyy-MM-dd");
    return (
      <option key={`startdateoption-${index}`} value={value}>
        {format(o, "dd/MM/yyyy")}
      </option>
    );
  });
};

export const UpdateParticipant = () => {
  const [loading, setLoading] = useState(false);
  const [startDateOptions, setStartDateOptions] = useState<any>();
  const [patientDetails, setPatientDetails] = useState({
    email: "",
    selectedStartDate: "",
  });

  const handleSubmitDetails = () => {
    const confirm = window.confirm(
      `Please confirm the participant details: 
      Email address: ${patientDetails.email}
      New start date: ${patientDetails.selectedStartDate}`
    );

    const request = () => {
      setLoading(true);
      return axios({
        method: "patch",
        url: `${apiEndpoint}lindus/patient/${patientDetails.email}/startDate`,
        data: {
          selectedStartDate: patientDetails.selectedStartDate,
        },
        headers: {
          "x-api-key": process.env.REACT_APP_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });
    };

    confirm &&
      request()
        .then((response: any) => {
          setLoading(false);
          alert("Participant updated successfully");
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
          const reason = error?.response?.data?.message || "";
          alert(`Something went wrong! ${reason}`);
        });
  };

  useEffect(() => {
    const newOptions = nextMondays(patientDetails, setPatientDetails, 12);
    setStartDateOptions(newOptions);
  }, []);

  return (
    <div className={"dash"}>
      <Sidebar activeTab="update" />
      <div className={"screen"}>
        {loading && (
          <div className="loadingOverlay">
            <div>Loading...</div>
          </div>
        )}
        <Fragment>
          <div className={"emailInput"}>
            <div className={"emailInputLabel cascade"}>
              <label>{"Please enter participant details to update:"}</label>
            </div>
          </div>
        </Fragment>
        {loading && (
          <div className="loadingOverlay">
            <div>Loading...</div>
          </div>
        )}
        <div
          style={{
            margin: 5,
            display: "flex",
            flexDirection: "column",
            maxWidth: "40%",
          }}
        >
          <div style={{ display: "grid" }}>
            <label>Email</label>
            <input
              value={patientDetails.email}
              onChange={(e: { target: { value: string } }) => {
                setPatientDetails({
                  ...patientDetails,
                  email: e.target.value.trim(),
                });
              }}
              type={"email"}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Start Date</label>

            <select
              onChange={(e) => {
                setPatientDetails({
                  ...patientDetails,
                  selectedStartDate: e.target.value,
                });
              }}
            >
              {startDateOptions}
            </select>
          </div>

          <button
            onClick={() => {
              handleSubmitDetails();
            }}
            disabled={
              !isEmailAddressValid(patientDetails.email) ||
              !patientDetails.selectedStartDate
            }
            className="cta"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
