import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import { AddressLookup } from "./AddressLookup";
import axios from "axios";
import { nextMonday, format, addWeeks } from "date-fns";
import isEmailAddressValid from "../helpers/isEmailAddressValid";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const nextMondays = (
  currentPatient: any,
  setPatient: any,
  numberOfMondays?: number
) => {
  const numberOfOptions = numberOfMondays || 10;
  const options = [];
  for (let i = 1; i < numberOfOptions; i++) {
    const date = addWeeks(nextMonday(new Date()), i);
    if (date > new Date("2023-01-09") && options.length < 6) {
      options.push(date);
    }
  }
  const firstOption = format(options[0], "yyyy-MM-dd");
  if (firstOption !== currentPatient.selectedStartDate) {
    setPatient({
      ...currentPatient,
      selectedStartDate: format(options[0], "yyyy-MM-dd"),
    });
  }

  return options.map((o, index) => {
    const value = format(o, "yyyy-MM-dd");
    const selected =
      value === currentPatient.selectedStartDate ? true : undefined;
    return (
      <option
        key={`startdateoption-${index}`}
        selected={selected}
        value={value}>
        {format(o, "dd/MM/yyyy")}
      </option>
    );
  });
};

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [addressDetails, setAddressDetails] = useState<any>({
    value: {},
    isValid: false,
  });
  const [addressLookupList, setAddressLookupList] = useState([]);
  const [tempKey, setTempKey] = useState(0);
  const [startDateOptions, setStartDateOptions] = useState<any>();
  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    selectedStartDate: "",
    gender: "unknown",
    control: false,
  });

  const handleSubmitDetails = (type: string = "email") => {
    const confirm = window.confirm(
      `Please confirm the participant details: 
      Control/intervention: ${
        patientDetails.control ? "Control" : "Intervention"
      }
      First Name: ${patientDetails.firstName}
      Last Name: ${patientDetails.lastName}
      Gender: ${patientDetails.gender}
      Email address: ${patientDetails.email}
      Start date: ${patientDetails.selectedStartDate}
      Phone: ${patientDetails.phone}
      Address: ${addressDetails.value.addressLine1.value}, ${
        addressDetails.value.addressLine2.value &&
        addressDetails.value.addressLine2.value + ", "
      }${addressDetails.value.townCity.value}, ${
        addressDetails.value.county.value
      }, ${addressDetails.value.postcode.value}`
    );

    const request = () => {
      setLoading(true);
      return axios({
        method: "post",
        url: `${apiEndpoint}api/v1/patient`,
        data: {
          ...patientDetails,
          trialPatient: true,
          shippingAddress: {
            addressLine1: addressDetails.value.addressLine1.value,
            addressLine2: addressDetails.value.addressLine2.value,
            townCity: addressDetails.value.townCity.value,
            county: addressDetails.value.county.value,
            postcode: addressDetails.value.postcode.value,
          },
        },
        headers: {
          "x-api-key": process.env.REACT_APP_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });
    };

    confirm &&
      request()
        .then((response: any) => {
          setLoading(false);
          window.location.reload();
          alert("Participant created successfully");
        })
        .catch((error: any) => {
          setLoading(false);
          alert(`Something went wrong
          ${error}`);
        });
  };

  useEffect(() => {
    const newOptions = nextMondays(patientDetails, setPatientDetails, 12);
    setStartDateOptions(newOptions);
  }, []);

  return (
    <div className={"dash"}>
      <Sidebar activeTab="dashboard" />
      <div className={"screen"}>
        {loading && (
          <div className="loadingOverlay">
            <div>Loading...</div>
          </div>
        )}
        <Fragment>
          <div className={"emailInput"}>
            <div className={"emailInputLabel cascade"}>
              <label>{"Please enter participant details:"}</label>
            </div>
          </div>
        </Fragment>
        {loading && (
          <div className="loadingOverlay">
            <div>Loading...</div>
          </div>
        )}
        <div
          style={{
            margin: 5,
            display: "flex",
            flexDirection: "column",
            maxWidth: "40%",
          }}>
          <div style={{ display: "grid" }}>
            <label style={{ flex: 1 }}>First Name</label>
            <input
              value={patientDetails.firstName}
              onChange={(e: { target: { value: any } }) => {
                setPatientDetails({
                  ...patientDetails,
                  firstName: e.target.value,
                });
              }}
              type={"text"}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Last Name</label>
            <input
              value={patientDetails.lastName}
              onChange={(e: { target: { value: any } }) => {
                setPatientDetails({
                  ...patientDetails,
                  lastName: e.target.value,
                });
              }}
              type={"text"}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Email</label>
            <input
              value={patientDetails.email}
              onChange={(e: { target: { value: string } }) => {
                setPatientDetails({
                  ...patientDetails,
                  email: e.target.value.trim(),
                });
              }}
              type={"email"}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Phone number</label>
            <input
              style={
                patientDetails.phone.length >= 9 &&
                patientDetails.phone.length < 11
                  ? { border: "1px red solid" }
                  : {}
              }
              placeholder="07777 777 777"
              value={patientDetails.phone}
              onChange={(e: { target: { value: string } }) =>
                setPatientDetails({
                  ...patientDetails,
                  phone: e.target.value.trim(),
                })
              }
              type={"tel"}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Gender</label>
            <select
              onChange={(e) => {
                setPatientDetails({
                  ...patientDetails,
                  gender: e.target.value,
                });
              }}>
              <option
                selected={patientDetails.gender === "female"}
                value={"female"}>
                Female
              </option>
              <option
                selected={patientDetails.gender === "male"}
                value={"male"}>
                Male
              </option>
              <option
                selected={patientDetails.gender === "unknown"}
                value={"unknown"}>
                Unknown
              </option>
            </select>
          </div>
          <div style={{ display: "grid" }}>
            <label>Postcode</label>
            <AddressLookup
              addressDetails={addressDetails}
              setAddressDetails={(details: any) => {
                setAddressDetails(details);
                return;
              }}
              addressLookupList={addressLookupList}
              setAddressLookupList={(addresses: any) => {
                setAddressLookupList(addresses);
              }}
              addressKey={tempKey}
              setAddressKey={() => {
                setTempKey(tempKey + 1);
              }}
              failureCallback={() => {
                return;
              }}
            />
          </div>
          <div style={{ display: "grid" }}>
            <label>Start Date</label>

            <select
              onChange={(e) => {
                setPatientDetails({
                  ...patientDetails,
                  selectedStartDate: e.target.value,
                });
              }}>
              {startDateOptions}
            </select>
          </div>

          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              checked={patientDetails.control}
              onChange={(e) => {
                setPatientDetails({
                  ...patientDetails,
                  control: !patientDetails.control,
                });
              }}
            />
            <label>Control participant?</label>
          </div>

          <button
            onClick={() => {
              handleSubmitDetails();
            }}
            disabled={
              !isEmailAddressValid(patientDetails.email) ||
              !patientDetails.firstName ||
              !patientDetails.lastName ||
              patientDetails.phone.length < 11 ||
              !addressDetails.value.addressLine1 ||
              !addressDetails.value.postcode
            }
            className="cta">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
