/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import React, { ReactNode } from "react";

import getMergedStyles from "../../helpers/getMergedStyles";
import findVariant from "../../helpers/findVariant";
import styles from "./Button.styles";

export enum BUTTON_VARIANTS {
  DISABLED = "disabled",
  CTA = "cta",
  CTA_INVERT = "ctaInvert",
  CTA_IMPACT = "ctaImpact",
  CTA_SMALL = "ctaSmall",
  UNDERLINED = "underlined",
  INLINE = "inline",
  PLUS = "plus",
}

interface Props {
  url?: string;
  onClick?: (e?: any) => void;
  copy?: string | ReactNode;
  icon?: ReactNode;
  isLoading?: boolean;
  target?: string;
  className?: string;
  label?: string;
  styles?: object;
  variant?: string | string[];
}

export const Button = ({
  url,
  onClick,
  copy,
  icon,
  isLoading = false,
  target,
  className,
  label,
  styles: parentStyles = {},
  variant = "",
}: Props) => {
  const { root, content, text }: { [key: string]: any } = getMergedStyles(
    styles,
    parentStyles,
    variant
  );
  const buttonCopy = isLoading ? "Please wait..." : copy;
  const tag = url ? "a" : "button";
  return (
    <button
      className={tag === "a" ? "link" : tag}
      {...{
        ...(url && !findVariant(variant, BUTTON_VARIANTS.DISABLED)
          ? { href: url }
          : {}),
        css: root,
        ...(onClick && !findVariant(variant, BUTTON_VARIANTS.DISABLED)
          ? { onClick }
          : {}),
        ...(findVariant(variant, BUTTON_VARIANTS.DISABLED)
          ? { disabled: true }
          : {}),
        ...(!url ? { type: "button" } : {}),
        ...(target ? { target } : {}),
        ...(className ? { className } : {}),
      }}
    >
      <span css={content}>
        {icon}
        <span css={text}>{buttonCopy}</span>
      </span>
    </button>
  );
};
