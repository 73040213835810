import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const Login = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const query = useQuery();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      navigate(`/dashboard${query && "?" + query}`);
    }
  }, [user, query, loading, navigate]);
  return (
    <div className="login">
      <div className="login__container">
        <button
          className="cta login__btn login__google"
          onClick={signInWithGoogle}
        >
          Login with Google
        </button>
      </div>
    </div>
  );
};
