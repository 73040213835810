import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";

const inlineRules = {
  display: "inline",
  fontSize: "inherit",
  whiteSpace: "pre-wrap",

  [`@media (min-width: ${breakpoints.tablet})`]: {
    fontSize: "inherit",
  },
};

const plusButtonContent = {
  content: '""',
  display: "block",
  backgroundColor: colors.secondary.white,
  position: "absolute",
  borderRadius: 20,
  transition: "transform 0.15s linear",
};

export default {
  root: {
    cursor: "pointer",
    display: "inline-block",
  },
  content: {
    fontSize: 16,
    width: "100%",
    textAlign: "center",

    [`@media (min-width: ${breakpoints.tablet})`]: {
      fontSize: 18,
    },
  },
  text: {},
  disabled: {
    root: {
      opacity: "0.3",
      pointerEvents: "none",
    },
  },
  cta: {
    content: {
      color: colors.secondary.white,
      fontWeight: 700,
      padding: "9px 24px",
      borderRadius: "100px",
      backgroundColor: "#3898EC",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  ctaInvert: {
    content: {
      color: "#3898EC",
      backgroundColor: colors.secondary.white,
    },
  },
  ctaImpact: {
    content: {
      padding: "14px 22px",

      [`@media (min-width: ${breakpoints.tablet})`]: {
        padding: "14px 28px",
      },
    },
  },
  ctaSmall: {
    content: {
      fontSize: 14,

      [`@media (min-width: ${breakpoints.tablet})`]: {
        fontSize: 14,
      },
    },
  },
  underlined: {
    content: {
      backgroundColor: "transparent",
      backgroundPosition: "50% 100%",
      backgroundRepeat: "repeat-x",
      color: colors.primary.darkGreen,
      padding: 0,

      [`@media (min-width: ${breakpoints.tablet})`]: {
        fontSize: 16,
      },
    },
  },
  inline: {
    root: {
      ...inlineRules,
    },
    content: {
      ...inlineRules,
    },
    text: {
      ...inlineRules,
    },
  },
  plus: {
    content: {
      width: 20,
      height: 20,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "transform 0.15s linear",
      color: colors.secondary.white,
      fontWeight: 700,
      borderRadius: "100px",
      backgroundColor: "#3898EC",

      "::before": {
        ...plusButtonContent,
        height: "50%",
        width: "10%",
      },

      "::after": {
        ...plusButtonContent,
        height: "10%",
        width: "50%",
      },
    },
  },
};
